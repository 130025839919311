<template>
    <b-card title=" Wheel Of Names">

        <div style="display: inline">
            <b-button size="md" variant="success" v-clipboard:copy="listWheelOfNamesCopied" v-clipboard:success="clipboardSuccessHandler" v-clipboard:error="clipboardErrorHandler" :disabled="isLoadingList">
            <feather-icon
                icon="CopyIcon"
                class="mr-50"
            /><span>{{ (!isLoadingList) ? 'Select All and Copy' : 'Loading..' }}</span>
            </b-button>
        </div>

        <div v-if="isLoadingList" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>

        <div  v-if="!isLoadingList" style="border: 1px solid #b4b7bd; padding: 10px; overflow: auto; width:100%; height: 250px; ; margin-top: 20px">
            <!-- List of names -->
            <div v-for="(item, index) in listWheelOfNames" :key="index">
              {{ item }}
            </div>
          </div>
    </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner
} from 'bootstrap-vue'
import Clipboard from 'v-clipboard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
    components: {
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BCard,
        BForm,
        BButton,
        BSpinner,
        Clipboard
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
        listWheelOfNames: [],
        isLoadingList: false,
        pageName: "Wheels Of Names",
        listWheelOfNamesCopied: ""
    }
  },
  computed: {

  },
  methods: {
    makeData(){
        for(var i = 0; i < this.listWheelOfNames.length; i++){
            this.listWheelOfNamesCopied += this.listWheelOfNames[i]+'\n';
        }
    },
    clipboardSuccessHandler (value, event) {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
            title: `Success`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Data copied to clipboard.',
            },
        })
    },
    clipboardErrorHandler (value, event) {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
            title: `Error`,
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'Data Failed copied to clipboard!',
            },
        })
    },
    fetchData() {
      this.isLoadingList = true

      this.$http.post('/admin/v1/feedback_responses', {
        event_id: this.eventId,
        event_booth_id: null
      })
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
          
            currentData.forEach(datum => {
                // console.log(datum.wheel_of_name)
                this.listWheelOfNames.push(datum.wheel_of_name)
            });
            this.makeData()
          }

          this.isLoadingList = false

     })
    },
  },
  created() {
    this.fetchData()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.responses-table .vgt-responsive {
  min-height: 350px;
}
</style>